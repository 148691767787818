import React from "react";
import { Container } from "@material-ui/core";
import { AchievementCard } from "../../common/cards";
import { achievementData } from "../../../config";

const Achievements = () => {
  return (
    <div className="achievements">
      <Container maxWidth="md">
        <h2 className="achievement-head">Our Achievements</h2>
        {achievementData.map((detail, index) => (
          <AchievementCard {...detail} key={`detail-${index}`} />
        ))}
      </Container>
    </div>
  );
};

export default Achievements;
