import male from "../svg/male_avatar.svg";
import female from "../svg/female_avatar.svg";
export const contacts = [
  {
    name: "Shubham Shinde",
    branch: "BE Comp",
    contctNo: "+918983493798",
    image: male,
  },
  {
    name: "Prathamesh Nalawade",
    branch: "BE Mech",
    contctNo: "+919096027603",
    image: male,
  },
  {
    name: "Varsha Hanbar",
    branch: "BE Mech",
    contctNo: "+919067039190",
    image: female,
  },
];
