import React from "react";

export const AchievementCard = (props) => {
  return (
    <div className="achievement-card">
      <div className="a-details">
        <h2 className="a-title">{props.title}</h2>
        <h3 className="a-year">{props.year}</h3>
        <div className="a-role">
          <p className="a-roletype">Team Leader</p>
          <p className="a-name">{props.teamLeader}</p>
        </div>
        <div className="a-role">
          <p className="a-roletype">Operator</p>
          <p className="a-name">{props.operator}</p>
        </div>
        <div className="a-role">
          <p className="a-roletype">Pit Crews</p>
          <p className="a-name">{props.pitCrews.join(", ")}</p>
        </div>
      </div>
      <div className="image-wrapper">
        <img src={props.image} alt={props.year} className="card-image" />
      </div>
    </div>
  );
};
